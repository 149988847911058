import React from 'react'
import PropTypes from 'prop-types'

import { LocaleContext } from '../Layout'
import * as S from './styled'

const KingsPage = ({ data }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const { main_title: title, main_text: text, kings_group: kingsGroup } = data

  return (
    <S.Wrapper>
      <S.InnerWrapper>
        <S.Title>{title}</S.Title>
        <S.Row>
          <S.Text dangerouslySetInnerHTML={{ __html: text.html }} />
        </S.Row>
        <S.Row>
          {kingsGroup.map(king => {
            const { data: content } = king.king.document[0]
            const {
              date_of_birth: birth,
              description,
              name,
              origin,
              image,
              pedigree,
              hcm_certificate: certificate,
              fiv_felv_certificate: fivFelv,
            } = content
            return (
              <S.OutterCol key={name}>
                <S.Card>
                  <S.CardRow>
                    <S.ThumbCol>
                      <S.Thumbnail src={image.url} alt={title} />
                    </S.ThumbCol>
                    <S.ContentCol>
                      <S.BreedTitle>{name}</S.BreedTitle>
                      <S.CardText>{description}</S.CardText>
                      <S.Row>
                        <S.Col>
                          <S.Label>{i18n.dob}</S.Label>
                          <S.CardText>{birth}</S.CardText>
                          <S.Label>{i18n.origin}</S.Label>
                          <S.CardText>{origin}</S.CardText>
                        </S.Col>
                        <S.Col>
                          {pedigree && pedigree.url && (
                            <S.ExternalLink target="_blank" rel="noopener noreferrer" href={pedigree.url}>
                              <S.Button>{i18n.pedigree}</S.Button>
                            </S.ExternalLink>
                          )}
                          {certificate && certificate.url && (
                            <S.ExternalLink target="_blank" rel="noopener noreferrer" href={certificate.url}>
                              <S.Button>{i18n.certificate}</S.Button>
                            </S.ExternalLink>
                          )}
                          {fivFelv && fivFelv.url && (
                            <S.ExternalLink target="_blank" rel="noopener noreferrer" href={fivFelv.url}>
                              <S.Button>{i18n.fiv}</S.Button>
                            </S.ExternalLink>
                          )}
                        </S.Col>
                      </S.Row>
                    </S.ContentCol>
                  </S.CardRow>
                </S.Card>
              </S.OutterCol>
            )
          })}
        </S.Row>
      </S.InnerWrapper>
    </S.Wrapper>
  )
}

KingsPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
}
export default KingsPage
