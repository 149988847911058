import styled from '@emotion/styled'

import { Row as DefaultRow, Col as DefaultCol } from '../UI/Grid'
import { Button as DefaultButton } from '../UI/Buttons'

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  padding: 64px 16px;
  width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 48px 16px;
  }
`

export const InnerWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
`
export const Title = styled.h2`
  font-size: 2rem;
  line-height: 1.4;

  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};

  span {
    color: ${({ theme }) => theme.colors.orange};
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.4rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.25rem;
  }
`

export const Text = styled.div`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.black};
  line-height: 2rem;

  padding: 32px 0;
`

export const OutterCol = styled(DefaultCol)`
  padding: 0;
  width: 100%;
  :not(:first-of-type) {
    margin-top: 32px;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    max-width: 100%;
  }
`

export const Col = styled(DefaultCol)`
  padding: 0;
  :not(:first-of-type) {
    margin-top: 32px;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    max-width: 100%;
  }
`

export const ThumbCol = styled(Col)`
  max-width: 338px;
  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    margin-right: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    flex-grow: 1;
    width: 100%;
    margin-right: 0;
  }
`

export const ContentCol = styled(Col)`
  padding-left: 16px;
  flex-grow: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    padding-left: 0;
  }
`
export const Row = styled(DefaultRow)`
  justify-content: space-between;
`

export const CardRow = styled(Row)`
  flex-wrap: nowrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    justify-content: flex-start;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    flex-wrap: wrap;
  }
`

export const Image = styled.img`
  width: 100%;
  border-radius: 4px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    max-height: 100%;
    width: 100%;
  }
`

export const Card = styled.div`
  background: ${({ theme }) => theme.colors.bg};
  border: 1px solid #ecebf5;
  box-shadow: 0 2px 16px 0 rgba(21, 30, 41, 0.06);
  border-radius: 4px;

  padding: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    max-width: 100%;
  }
`

export const Button = styled(DefaultButton)`
  width: 100%;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    max-width: 100%;
  }
`
export const BreedTitle = styled.p`
  font-size: 1.3rem;
  font-weight: bold;
  padding: 16px 0;
`

export const Thumbnail = styled.div`
  min-width: 338px;
  min-height: 210px;
  height: 100%;
  border-radius: 4px;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    margin-right: 16px;
    max-width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    margin-right: 0;
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.s}) {
    min-width: 100%;
  }
`

export const ExternalLink = styled.a`
  margin-top: 16px;
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    justify-content: flex-start;
  }
`

export const Label = styled.p`
  padding: 16px 0 0 0;
  color: ${({ theme }) => theme.colors.orange};
`

export const CardText = styled(Text)`
  padding: 0;
`
